import './Whatis.scss'

import { APIWhatis } from 'src/API/WhatIs'
import { SuperScroll } from 'src/Components/SuperScroll/SuperScroll'
import { BrToBr } from 'src/Helper/BrToBr'

import { ICNNumber } from '@Icon/ICNNumber'

export const Whatis = () => {
  const Content = APIWhatis()

  return (
    <div className="page fixed-width fragment-whatis" id="faq">
      <div className="row">
        <div
          className="background"
          style={{ backgroundImage: 'url(/images/faq.jpg)' }}
        ></div>
        <div className="content">
          <SuperScroll
            items={(Content || []).map((item, idx) => ({
              svgIcon: <ICNNumber num={idx + 1} />,
              content: (
                <div className="whatis-item">
                  <h3>{item.title}</h3>
                  <p>{BrToBr(item.text)}</p>
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </div>
  )
}
