import { MutableRefObject, useEffect, useRef } from 'react'

export const useHorizontalScroll = (): [
  MutableRefObject<HTMLInputElement>,
  () => void,
  () => void
] => {
  const elRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>

  const setTitle = (title: string) => {
    window.history.pushState({}, '', `#${title}`)
    document.title = `${title.toUpperCase()} - Esport Legends`
  }

  const scrollToFragment = (id: number, smooth?: boolean) => {
    // let totalLeft = 0
    const items = document.querySelectorAll('.page')
    if (items.length <= id) {
      console.error(`[ELEGENDS::NAVIGATION] PAGE #${id} not found!`)

      return
    }
    setTitle(items[id].id)
    const left =
      elRef.current.scrollLeft + items[id].getBoundingClientRect().left
    console.log(left, items[id].getBoundingClientRect())
    elRef.current.scrollTo({
      left,
      // eslint-disable-next-line no-constant-condition
      behavior: smooth || true ? 'smooth' : 'auto',
    })
  }

  const goRight = () => {
    if (!elRef.current) return
    const items = document.querySelectorAll('.page')
    const hash = window.location.hash.replace('#', '')
    for (let i = 0; i < items.length - 1; i++) {
      try {
        if (items[i].id === hash) {
          scrollToFragment(i + 1)
        }
      } catch (e) {
        console.error(`[ELEGENDS::NAVIGATION] PAGE #${i} not found!`)
      }
    }
  }

  const goLeft = () => {
    if (!elRef.current) return
    const items = document.querySelectorAll('.page')
    const hash = window.location.hash.replace('#', '')
    if (hash === '') {
      scrollToFragment(items.length - 2)
    } else {
      for (let i = 1; i < items.length; i++) {
        try {
          if (items[i].id === hash) {
            scrollToFragment(i - 1)
          }
        } catch (e) {
          console.error(`[ELEGENDS::NAVIGATION] PAGE #${i} not found!`)
        }
      }
    }
  }

  useEffect(() => {
    const el = elRef.current
    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (window.innerWidth < 1200) return
        e.preventDefault()
        if (e.deltaY === 0) return
        if (e.deltaY > 0) goRight()
        else goLeft()
      }
      el.addEventListener('wheel', onWheel)
      const home = document.querySelectorAll('.page')
      const hash = window.location.hash.replace('#', '')
      // window.addEventListener('resize', e => {
      //   home.forEach((item, idx) => {
      //     if (item.id === hash) {
      //       scrollToFragment(idx, false)
      //     }
      //   })
      // })
      if (hash === '') {
        setTitle('home')
      }
      home.forEach((item, idx) => {
        if (item.id === hash) {
          scrollToFragment(idx, false)
        }
      })

      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])

  return [elRef, goLeft, goRight]
}
