import './SuperScroll.scss'

import { ReactElement, useState } from 'react'

import { IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ISuperScrollItem {
  icon?: IconDefinition
  svgIcon?: ReactElement
  content: ReactElement
}

interface ISuperScrollProps {
  items: ISuperScrollItem[]
}

export const SuperScroll = (props: ISuperScrollProps) => {
  const [current, setCurrent] = useState(0)

  return (
    <div className="super-scroll">
      <div className="categories">
        {props.items.map((item, idx) => (
          <a
            key={idx}
            href="#"
            onClick={e => {
              e.preventDefault()
              setCurrent(idx)
            }}
            className={idx === current ? 'active' : ''}
          >
            {item.icon && <FontAwesomeIcon icon={item.icon} />}
            {item.svgIcon && <>{item.svgIcon}</>}
          </a>
        ))}
      </div>
      <div className="contents">
        {props.items.map((item, idx) => (
          <div key={idx} className={`item ${idx === current ? 'active' : ''}`}>
            {item.content}
          </div>
        ))}
      </div>
    </div>
  )
}
