import './ContentScroll.scss'

import { ReactElement, useState } from 'react'

import { ContentScrollPagination } from './ContentScrollPagination'

interface IContentScrollProps {
  items: ReactElement[]
  title: string
  start?: number
}

export const ContentScroll = (props: IContentScrollProps) => {
  const [active, setActive] = useState(props.start ?? 1)

  return (
    <div className="content-scroll">
      <div className="content" data-title={props.title}>
        <div className={`deamon ${active <= 0 ? 'sib' : ''}`}>
          {props.items[0]}
        </div>
        {props.items.map((item, idx) => {
          let className = idx === active ? 'active' : ''

          if (idx + 1 === active || idx - 1 === active) className = 'sib'

          return (
            <div
              key={idx}
              className={className}
              data-item={idx}
              data-active={active}
              onClick={e => {
                if (className === 'sib') {
                  e.preventDefault()
                  e.stopPropagation()
                  setActive(idx)
                }
              }}
            >
              {item}
            </div>
          )
        })}
        <div
          className={`deamon ${active + 1 === props.items.length ? 'sib' : ''}`}
        >
          {props.items[0]}
        </div>
      </div>
      {props.items.length > 1 && (
        <div className="scroll">
          <ContentScrollPagination
            key={`${active}-${props.items.length}`}
            current={active}
            max={props.items.length}
            onChange={setActive}
          />
        </div>
      )}
    </div>
  )
}
