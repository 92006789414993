export const StatsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="17"
    viewBox="0 0 14 17"
  >
    <path
      id="icon"
      d="M11.2,17V8.5H14V17ZM5.6,17V0H8.4V17ZM0,17V5.667H2.8V17Z"
      fill="#5e627e"
    />
  </svg>
)
