import './PageHome.scss'

import { useEffect, useState } from 'react'
import { APISocialmedia } from 'src/API/Socialmedia'

import * as Icon from '@fortawesome/free-brands-svg-icons'

import { Ornament } from '../Components/Home/Ornament/Ornament'
import { SocialmediaHome } from '../Components/Socialmedia/SocialmediaHome'

export const PageHome = ({ timestamp }: { timestamp: number }) => {
  const Socialmedia = APISocialmedia()

  const [time, setTime] = useState({
    days: 30,
    hours: 24,
    minutes: 60,
    seconds: 60,
  })

  const timer = () => {
    const dateFuture = new Date(timestamp * 1000)
    const dateNow = new Date()

    let seconds = Math.floor((dateFuture.getTime() - dateNow.getTime()) / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    hours -= days * 24
    minutes = minutes - days * 24 * 60 - hours * 60
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60
    setTime({
      hours,
      minutes,
      seconds,
      days,
    })
  }

  useEffect(() => {
    setInterval(timer, 1000)
    timer()
  }, [])

  return (
    <div className="page fixed-width page-home" id="home">
      <Ornament />
      <Ornament />
      <div>
        <img className="logo_large" src="/logo_large.png" alt="" />
        <img className="logo_small" src="/logo_small.png" alt="" />
        {/* <a href="#" onClick={props.onClick}>
          <StartBrowsing />
        </a> */}
        {timestamp * 1000 > new Date().getTime() && (
          <div className="countdown">
            <span>
              <span>{String(time.days).padStart(2, '0')}</span>
              <small>Tage</small>
            </span>
            <span>
              <span>{String(time.hours).padStart(2, '0')}</span>
              <small>Stunden</small>
            </span>
            <span>
              <span>{String(time.minutes).padStart(2, '0')}</span>
              <small>Minuten</small>
            </span>
            <span>
              <span>{String(time.seconds).padStart(2, '0')}</span>
              <small>Sekunden</small>
            </span>
          </div>
        )}
        <div id="mouse"></div>
        <div className="social">
          {Socialmedia?.map((item, idx) => {
            // Needs to be ignored due to icondefinition by string
            // maybe there is a better way but not needed atm
            // @ts-ignore
            const icon = Icon[item.icon]

            return <SocialmediaHome {...item} icon={icon} key={idx} />
          })}
        </div>
      </div>
    </div>
  )
}
