import { IAPINewsSmall } from '@Interfaces/IAPINewsSmall'

export const NewsItem = (props: IAPINewsSmall) => {
  const image = props.isSoon ? '/images/news/soon.jpg' : props.image

  return (
    <div className={`news-item ${props.isSoon ? 'soon' : ''}`}>
      <div className={`text ${props.isSoon ? 'soon' : ''}`}>
        {props.category && <h3>{props.category}</h3>}
        {props.title && <h2>{props.title}</h2>}
        {props.date && <span>{props.date}</span>}
        {props.text && <p>{props.text}</p>}
        {props.isSoon && (
          <>
            <div></div>
            <h4>more news coming soon...</h4>
            <div></div>
          </>
        )}
      </div>
      <div className="side" style={{ backgroundImage: `url(${image})` }}></div>
    </div>
  )
}
