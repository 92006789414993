import './SocialmediaHome.scss'

import { IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ISocialmediaHomeProps {
  url: string
  icon: IconDefinition
  name: string
  uri: string
}

export const SocialmediaHome = (props: ISocialmediaHomeProps) => (
  <a
    target={props.url === '#' ? '_self' : '_blank'}
    rel="noopener nofollow"
    href={props.url}
    className="socialmedia-link"
  >
    <FontAwesomeIcon icon={props.icon} />
    <span>
      <small>{props.name}</small>
      <b>{props.uri}</b>
    </span>
  </a>
)
