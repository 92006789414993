import './PageNews.scss'

import { APINews } from 'src/API/News'
import { BrToBr } from 'src/Helper/BrToBr'

import { NewsItem } from './NewsItem'

export const PageNews = () => {
  const News = APINews()

  let len = News?.small.length || 0
  if (len % 2 !== 0) len++

  return (
    <div
      className="page dynamic-width fragment-news"
      id="news"
      style={{
        width: `calc(${
          len > 2 ? 80 : 100
        }vw - var(--content-height) + ((var(--content-height) / 2) * ${len}))`,
      }}
    >
      <div className="row">
        <div
          className={`image ${len > 2 ? 'small' : ''}`}
          style={{
            backgroundImage: `url(${News?.big.image})`,
          }}
        >
          <div>
            <span>{News?.big.category}</span>
            {News?.big.title?.map(text => (
              <h2 key={text}>{text}</h2>
            ))}
            <small>{News?.big.date}</small>
            <p>{BrToBr(News?.big.text || '')}</p>
          </div>
        </div>
        <div className="news">
          {News?.small.map((e, id) => (
            <NewsItem {...e} key={id} />
          ))}
          {(News?.small.length || 0) % 2 !== 0 && <NewsItem isSoon={true} />}
        </div>
      </div>
    </div>
  )
}
