export const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.016"
    height="14.018"
    viewBox="0 0 8.016 14.018"
  >
    <path
      id="Right_Arrow"
      data-name="Right Arrow"
      d="M0,12.615,5.344,7.009,0,1.4,1.336,0,6.68,5.607h0l1.336,1.4L6.68,8.411h0L1.336,14.018Z"
      fill="#fff"
    />
  </svg>
)
