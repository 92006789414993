export const ArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.015"
    height="14.018"
    viewBox="0 0 8.015 14.018"
  >
    <path
      id="Left_Arrow_"
      data-name="Left Arrow "
      d="M0,7.008,6.68,0,8.015,1.4,2.672,7.009l5.343,5.607-1.336,1.4Z"
      fill="#fff"
    />
  </svg>
)
