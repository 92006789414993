export const ICNNumber = (props: { num: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="24"
    viewBox="0 0 17 24"
  >
    <text
      transform="translate(4 20)"
      fill="#fff"
      fontSize="20"
      fontFamily="Kallisto-Heavy, Kallisto"
      fontWeight="800"
    >
      <tspan x={props.num === 1 ? '' : '-3.97'} y="0">
        {props.num}
      </tspan>
    </text>
  </svg>
)
