import './Navbar.scss'

import { ReactNode, useEffect, useState } from 'react'
import { APISocialmedia } from 'src/API/Socialmedia'

import * as FA from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ArrowLeft } from '@Icon/ICNArrowLeft'
import { ArrowRight } from '@Icon/ICNArrowRight'
import { Social } from '@Icon/ICNSocial'

import { Button } from '../Button/Button'
import { Popup } from '../Popup/Popup'

interface INavbarProps {
  goLeft: () => void
  goRight: () => void
  items: string[]
  dashed?: boolean
  footer?: ReactNode
}

export const Navbar = (props: INavbarProps) => {
  const [whitepaperPopup, setWhitepaperPopup] = useState(false)
  const [show, setShow] = useState(false)
  const [active, setActive] = useState('home')
  const Socialmedia = APISocialmedia()

  useEffect(() => {
    let temp = 'home'
    setInterval(() => {
      const n = window.location.hash.replace(/#/, '')
      if (n !== temp) {
        temp = n
        setActive(n)
      }
    }, 300)
  }, [])

  const setLanguage = (e: any, lang: string) => {
    e.preventDefault()
    localStorage.setItem('language', lang || 'de')
    window.location.reload()
  }

  return (
    <>
      {whitepaperPopup && (
        <Popup
          title="Whitepaper"
          content={
            (localStorage.getItem('language') ?? 'de') === 'de'
              ? 'Das Whitepaper erhalten Sie hier in Kürze!'
              : 'The Whitepaper is comming soon!'
          }
          onClose={() => {
            setWhitepaperPopup(false)
          }}
        />
      )}
      <div
        className={`navbar ${props.dashed ? 'dashed' : ''} ${
          show ? 'show' : 'hide'
        }`}
        onClick={e => {
          if (!(e.target as HTMLElement).classList.contains('navbar')) return
          setShow(false)
        }}
      >
        <div className="dummy"></div>
        <img className="logo_small" src="/images/logo_small.png" alt="" />
        <img className="logo_large" src="/logo_large.png" alt="" />
        <button
          onClick={e => {
            e.preventDefault()
            setShow(!show)
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="navbar-inner">
          <ul>
            {props.items.map(item => (
              <li key={item} className={item === active ? 'active' : ''}>
                <a
                  href={`#${item}`}
                  onClick={e => {
                    e.preventDefault()
                    window.history.pushState({}, '', `#${item}`)

                    const main = document.querySelector('#root > #main')
                    const html = document.querySelector('html')
                    const itm = document
                      .querySelector(`#${item}`)
                      ?.getBoundingClientRect()

                    const docLeft = main?.scrollLeft || 0
                    const itemLeft = itm?.x || 0

                    const docTop = html?.scrollTop || 0
                    const itemTop = itm?.y || 0

                    const left = docLeft + itemLeft
                    const top = docTop + itemTop

                    if (window.innerWidth < 1200) {
                      html?.scrollTo({ top, behavior: 'smooth' })
                      setShow(false)
                    } else {
                      document
                        .querySelector('#root > #main')
                        ?.scrollTo({ left, behavior: 'smooth' })
                    }
                  }}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
          <div className="navbar-buttons">
            <Button
              text="Join Discord"
              type="primary"
              outline
              rounded
              url="https://discord.gg/sPmyPgNYea"
            />
            <Button
              text="Whitepaper"
              type="primary"
              rounded
              onClick={() => setWhitepaperPopup(true)}
            />
            <a
              href="#"
              className="nav nav-left"
              onClick={e => {
                e.preventDefault()
                props.goLeft()
              }}
            >
              <ArrowLeft />
            </a>
            <div className="nav nav-filter">
              <span>
                {
                  { de: 'DE', en: 'EN' }[
                    localStorage.getItem('language') || 'de'
                  ]
                }
              </span>
              <div className="filter-menu">
                {['de', 'en'].map(item => (
                  <a
                    key={item}
                    className={
                      localStorage.getItem('language') === item ? 'active' : ''
                    }
                    onClick={e => setLanguage(e, item)}
                    href="#"
                  >
                    {item.toUpperCase()}
                  </a>
                ))}
              </div>
            </div>
            <a
              href=""
              className="nav nav-right"
              onClick={e => {
                e.preventDefault()
                props.goRight()
              }}
            >
              <ArrowRight />
            </a>
            <a href="" className="social">
              <Social />
            </a>
            <div className="social-box">
              {Socialmedia?.map(item => {
                // @ts-ignore
                const icon: FA.IconDefinition = FA[item.icon]

                return (
                  <a
                    href={item.url}
                    key={item.name}
                    style={{
                      backgroundColor: item.color,
                      borderColor: item.color,
                    }}
                  >
                    <FontAwesomeIcon className="icon" icon={icon} />
                    <span>
                      <span>/{item.uri}</span>
                      <b>/{item.uri}</b>
                      <small>{item.slogan}</small>
                    </span>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
