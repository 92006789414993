import './VerticalScroll.scss'

import { MutableRefObject, ReactNode, useEffect, useRef } from 'react'

interface IVerticalScrollProps {
  children: ReactNode
  hoverable?: boolean
  initScroll?: number
}
export const VerticalScroll = (props: IVerticalScrollProps) => {
  const elRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>

  useEffect(() => {
    const el = elRef.current
    if (el) {
      if (props.initScroll !== undefined) {
        elRef.current.scrollTo({ top: props.initScroll })
      }
      const onWheel = (e: WheelEvent) => {
        e.stopPropagation()
      }
      el.addEventListener('wheel', onWheel)

      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])

  useEffect(() => {
    const el = elRef.current
    if (el) {
      if (props.initScroll !== undefined) {
        elRef.current.scrollTo({ top: props.initScroll })
      }
    }
  }, [props.initScroll])

  return (
    <div
      ref={elRef}
      className={`scroll-box ${
        props.hoverable ? 'hoverable' : 'not-hoverable'
      }`}
    >
      {props.children}
    </div>
  )
}
