import axios from 'axios'
import { useEffect, useState } from 'react'

export const useAPI = <T,>(url: string, override?: string) => {
  const [ret, setRet] = useState<T>()

  // Checking and overriding Language
  let language = localStorage.getItem('language')
  if (override) language = override

  useEffect(() => {
    // Checking cache
    const temp = localStorage.getItem(url)
    if (temp) {
      setRet(JSON.parse(temp))
    }

    // Requesting fresh data
    axios
      .get<T>(`https://api.esportlegends.gg/${language}${url}`)
      .then(({ data }) => {
        setRet(data)
        localStorage.setItem(url, JSON.stringify(data))
      })
  }, [])

  return ret
}
