import './Team.scss'

import { useState } from 'react'
import { APITeam } from 'src/API/Team'

import * as Icon from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Team = () => {
  const Data = APITeam()
  const [active, setActive] = useState(0)

  return (
    <div
      className="page fixed-width fragment-team"
      id="team"
      style={{
        backgroundImage: 'url(/images/bg_play.jpg)',
      }}
    >
      <div className="content">
        {(Data || []).map((item, idx) => (
          <div
            key={item.name}
            className={`member ${idx === active ? 'active' : ''}`}
          >
            <div className="info">
              <span>{item.position}</span>
              <h3>{item.company}</h3>
            </div>
            <div className="favorites">
              <div>
                <div className="favorite">
                  <small>Mainrole</small>
                  <span>{item.info.mainrole}</span>
                </div>
                <div className="favorite">
                  <small>favorite champ</small>
                  <span>{item.info.favoriteChamp}</span>
                </div>
                <div className="favorite">
                  <small>favorite team</small>
                  <span>{item.info.favoriteTeam}</span>
                </div>
                <div className="favorite">
                  <small>favorite player</small>
                  <span>{item.info.favoritePlayer}</span>
                </div>
              </div>
            </div>
            <div className="person">
              <small>{item.realName}</small>
              <h2>{item.name}</h2>
              <div className="socialmedia">
                {item.socialmedia.map(sm => {
                  // @ts-ignore
                  const icon: Icon.IconDefinition = Icon[sm.icon]

                  return (
                    <a
                      target="_blank"
                      rel="nofollow noopener"
                      key={sm.icon}
                      href={sm.url}
                    >
                      <FontAwesomeIcon icon={icon} />
                    </a>
                  )
                })}
              </div>
            </div>
            <div className="image">
              <img src={item.image} alt="" />
            </div>
          </div>
        ))}
      </div>
      <div className="select">
        {(Data || []).map((item, idx) => (
          <a
            key={item.name}
            href="#"
            onClick={e => {
              e.preventDefault()
            }}
            className={idx === active ? 'active' : ''}
          >
            <span
              onClick={e => {
                e.preventDefault()
                setActive(idx)
              }}
            >
              <span
                style={{ backgroundImage: `url(${item.small_image})` }}
              ></span>
            </span>
          </a>
        ))}
      </div>
    </div>
  )
}
