import './Roadmap.scss'

import { APIRoadmap } from 'src/API/Roadmap'

export const Roadmap = () => {
  const Data = APIRoadmap()

  return (
    <div
      className="page fixed-width fragment-roadmap"
      id="roadmap"
      style={{
        backgroundImage: 'url(/images/bg_play.jpg)',
      }}
    >
      <div className="roadmap">
        {(Data || []).map(item => (
          <div
            key={item.date}
            className={`item ${item.isActive ? 'active' : ''}`}
          >
            <h3>{item.date}</h3>
            <ul>
              {item.items.map(itm => (
                <li key={itm}>{itm}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
