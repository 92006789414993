import React from 'react'

export const PcIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.593"
    height="16.593"
    viewBox="0 0 16.593 16.593"
  >
    <g transform="translate(-81 -416)" fill="none">
      <path
        d="M94.828,427.062H90.679v2.766h2.766v2.766h-8.3v-2.766h2.766v-2.766H83.766A2.765,2.765,0,0,1,81,424.3v-5.531A2.765,2.765,0,0,1,83.766,416H94.828a2.766,2.766,0,0,1,2.766,2.766V424.3A2.766,2.766,0,0,1,94.828,427.062Zm0-8.3H83.766V424.3H94.828Z"
        stroke="none"
      />
      <path
        id="icon"
        d="M 93.44481658935547 432.5930786132812 L 85.14829254150391 432.5930786132812 L 85.14829254150391 429.8276062011719 L 87.91376495361328 429.8276062011719 L 87.91376495361328 427.0620727539062 L 83.7655029296875 427.0620727539062 C 82.23813629150391 427.0620727539062 81.00003051757812 425.823974609375 81.00003051757812 424.2966003417969 L 81.00003051757812 418.7654724121094 C 81.00003051757812 417.2381286621094 82.23813629150391 416 83.7655029296875 416 L 94.82755279541016 416 C 96.35482025146484 416 97.59307861328125 417.2381286621094 97.59307861328125 418.7654724121094 L 97.59307861328125 424.2966003417969 C 97.59307861328125 425.823974609375 96.35482025146484 427.0620727539062 94.82755279541016 427.0620727539062 L 90.67929077148438 427.0620727539062 L 90.67929077148438 429.8276062011719 L 93.44481658935547 429.8276062011719 L 93.44481658935547 432.5930786132812 Z M 83.7655029296875 418.7654724121094 L 83.7655029296875 424.2966003417969 L 94.82755279541016 424.2966003417969 L 94.82755279541016 418.7654724121094 L 83.7655029296875 418.7654724121094 Z"
        stroke="none"
        fill="#5e627e"
      />
    </g>
  </svg>
)
