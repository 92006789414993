import './Vision.scss'

import React from 'react'
import { APIVision } from 'src/API/Vision'
import { BrToBr } from 'src/Helper/BrToBr'

export const VisionContent = () => {
  const Vision = APIVision()

  return (
    <div className="vision-content">
      <div>
        {Vision?.map((item, idx) => (
          <React.Fragment key={idx}>
            <h3>{item.title}</h3>
            <p>{BrToBr(item.text)}</p>
          </React.Fragment>
        ))}
        <h4 className="title" style={{ textAlign: 'left' }}>
          The Game
        </h4>
      </div>
    </div>
  )
}
