import './Button.scss'

export interface IButtonMainProps extends IButtonProps {
  type: 'default' | 'error' | 'warning' | 'success' | 'primary' | 'info'
}
export interface IButtonProps {
  url?: string
  outline?: boolean
  text: string
  rounded?: boolean
  disabled?: boolean
  onClick?: () => void
}
export const Button = (props: IButtonMainProps) => (
  <a
    className={`btn ${props.type} ${props.outline ? 'outline' : ''} ${
      props.rounded ? 'rounded' : ''
    } ${props.disabled ?? false ? 'disabled' : ''}`}
    href={props.url}
    onClick={e => {
      e.preventDefault()
      if (props.onClick && !(props.disabled ?? false)) {
        props.onClick()
      }
    }}
  >
    <span>{props.text}</span>
  </a>
)
