import React from 'react'

import { Circle } from '@Icon/ICNCircle'

import { Arrow } from '../../icons/ICNArrow'

interface IContentScrollPaginationProps {
  onChange: (_val: number) => void
  current: number
  max: number
}
export const ContentScrollPagination = (
  props: IContentScrollPaginationProps
) => {
  const arr: null[] = []
  arr.length = props.max
  arr.fill(null)

  const change = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    add: number
  ) => {
    e.preventDefault()
    if (add < 0 && props.current <= 0) return
    if (add > 0 && props.current + 1 >= props.max) return
    props.onChange(props.current + add)
  }

  const opacityBefore = props.current <= 0 ? 0.2 : 1
  const opacityAfter = props.current + 1 >= props.max ? 0.2 : 1

  return (
    <>
      <a
        href="#up"
        onClick={e => change(e, -1)}
        style={{ opacity: opacityBefore }}
      >
        <Arrow />
      </a>
      {arr.map((_, idx) => (
        <a
          key={idx}
          href={`#${idx}`}
          className={idx === props.current ? 'active' : ''}
          onClick={e => {
            e.preventDefault()
            props.onChange(idx)
          }}
        >
          <Circle />
        </a>
      ))}
      <a
        href="#down"
        onClick={e => change(e, 1)}
        style={{ opacity: opacityAfter }}
      >
        <Arrow />
      </a>
    </>
  )
}
