import React from 'react'

export const AchievementsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="21"
    viewBox="0 0 17 21"
  >
    <g fill="none" stroke="#5e627e" strokeWidth="2" id="strokecon">
      <rect width="17" height="21" rx="4" stroke="none" />
      <rect x="1" y="1" width="15" height="19" rx="3" fill="none" />
    </g>
  </svg>
)
