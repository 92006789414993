import './Play.scss'

import { Leaderboard } from 'src/Components/Content/Leaderboard/Leaderboard'
import { StatsContent } from 'src/Components/Content/Stats/Stats'

import { AchievementsIcon } from '@Icon/ICNAchievements'
import { PcIcon } from '@Icon/ICNPc'
import { PlayIcon } from '@Icon/ICNPlay'
import { StatsIcon } from '@Icon/ICNStats'
import { UserIcon } from '@Icon/ICNUser'

import { PlayContent } from '../../Components/Content/Play/Play'
import { TradingCardsContent } from '../../Components/Content/TradingCards/TradingCards'
import { VisionContent } from '../../Components/Content/Vision/Vision'
import { SuperScroll } from '../../Components/SuperScroll/SuperScroll'

export const Play = () => (
  <div className="page fixed-width fragment-play" id="details">
    <div className="row">
      <div
        className="background"
        style={{ backgroundImage: 'url(/images/playbackground.jpg)' }}
      ></div>
      <div className="content">
        <SuperScroll
          items={[
            {
              svgIcon: <PcIcon />,
              content: <VisionContent />,
            },
            {
              svgIcon: <PlayIcon />,
              content: <PlayContent />,
            },
            {
              svgIcon: <AchievementsIcon />,
              content: <TradingCardsContent />,
            },
            {
              svgIcon: <StatsIcon />,
              content: <Leaderboard />,
            },
            {
              svgIcon: <UserIcon />,
              content: <StatsContent />,
            },
          ]}
        />
      </div>
    </div>
  </div>
)
