import './ContentHScroll.scss'

import { ReactElement, useState } from 'react'

import { ContentScrollPagination } from '../ContentScroll/ContentScrollPagination'

interface IContentHScrollProps {
  items: ReactElement[]
  title: string
  start?: number
}

export const ContentHScroll = (props: IContentHScrollProps) => {
  const [active, setActive] = useState(props.start ?? 1)
  const [x, setX] = useState(0)

  const move = (e: any) => {
    const { clientX } = e.targetTouches[0]
    if (clientX === 0) return
    if (clientX < x - 80) {
      if (active + 1 >= props.items.length) return
      setActive(active + 1)
      setX(clientX)
    }
    if (clientX > x + 80) {
      if (active - 1 < 0) return
      setActive(active - 1)
      setX(clientX)
    }
  }
  const moveStart = (e: any) => {
    setX(e.targetTouches[0].clientX)
  }
  const moveStop = () => {
    setX(0)
  }

  return (
    <div className="content-h-scroll">
      <div
        className="content"
        data-title={props.title}
        onTouchStart={moveStart}
        onTouchEnd={moveStop}
        onTouchMove={move}
      >
        <div className="inner-content">
          <div className={`deamon ${active <= 0 ? 'sib' : ''}`}>
            {props.items[0]}
          </div>
          {props.items.map((item, idx) => {
            let className = idx === active ? 'active' : ''

            if (idx + 1 === active || idx - 1 === active) className = 'sib'

            return (
              <div
                key={idx}
                className={className}
                onClick={e => {
                  if (className === 'sib') {
                    e.preventDefault()
                    e.stopPropagation()
                    setActive(idx)
                  }
                }}
              >
                {item}
              </div>
            )
          })}
          <div
            className={`deamon ${
              active + 1 === props.items.length ? 'sib' : ''
            }`}
          >
            {props.items[0]}
          </div>
        </div>
      </div>
      <div className="scroll">
        <ContentScrollPagination
          current={active}
          max={props.items.length}
          onChange={setActive}
        />
      </div>
    </div>
  )
}
