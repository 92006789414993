import './Flyout.scss'

import { ReactElement, useEffect, useState } from 'react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { VerticalScroll } from '../VerticalScroll/VerticalScroll'

interface IFlyoutProps {
  title: string
  content: ReactElement
  show: boolean
  onClose: () => void
}

export const Flyout = (props: IFlyoutProps) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(props.show)
  }, [props.show])

  return (
    <div
      className={`flyout ${show ? 'show' : 'hidden'}`}
      onKeyDown={e => {
        if (e.key === 'Escape' && show) {
          props.onClose()
        }
      }}
      tabIndex={1}
    >
      <div className="content">
        <div className="heading">
          <h2>{props.title}</h2>
          <a href="#" onClick={() => props.onClose()}>
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
        <div className="text">
          <VerticalScroll hoverable>{props.content}</VerticalScroll>
        </div>
      </div>
    </div>
  )
}
