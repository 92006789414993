import './TradingCards.scss'

import { APITradingcards } from 'src/API/Tradingcards'
import { ContentHScroll } from 'src/Components/ContentHScroll/ContentHScroll'

export const TradingCardsContent = () => {
  const Items = APITradingcards()

  return (
    <div id="tradingcardScroller">
      <ContentHScroll
        title="tradingcards"
        start={1}
        items={(Items || []).map(item => (
          <div key={item.name} className="trading-card">
            <img src={item.image} alt="" />
            <h3 style={{ color: item.color }}>{item.name}</h3>
            {item.text.map(text => (
              <h4 key={text}>{text}</h4>
            ))}
          </div>
        ))}
      />
    </div>
  )
}
