import { Flyout } from '../Flyout/Flyout'

export const DataprivacyEN = ({
  show,
  setShow,
}: {
  show: boolean
  setShow: (_show: boolean) => void
}) => (
  <Flyout
    title="Dataprivacy"
    show={show}
    onClose={() => setShow(false)}
    content={
      <>
        <div className="container">
          <h1>Website Terms and Conditions of Use</h1>
          <h2>1. Terms</h2>
          <p>
            By accessing this Website, accessible from www.esportlegends.gg, you
            are agreeing to be bound by these Website Terms and Conditions of
            Use and agree that you are responsible for the agreement with any
            applicable local laws. If you disagree with any of these terms, you
            are prohibited from accessing this site. The materials contained in
            this Website are protected by copyright and trade mark law.
          </p>
          <h2>2. Use License</h2>
          <p>
            Permission is granted to temporarily download one copy of the
            materials on Team Inseparable Esports UG (haftungsbeschränkt)'s
            Website for personal, non-commercial transitory viewing only. This
            is the grant of a license, not a transfer of title, and under this
            license you may not:
          </p>
          <ul>
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose or for any public
              display;
            </li>
            <li>
              attempt to reverse engineer any software contained on Team
              Inseparable Esports UG (haftungsbeschränkt)'s Website;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transferring the materials to another person or "mirror" the
              materials on any other server.
            </li>
          </ul>
          <p>
            This will let Team Inseparable Esports UG (haftungsbeschränkt) to
            terminate upon violations of any of these restrictions. Upon
            termination, your viewing right will also be terminated and you
            should destroy any downloaded materials in your possession whether
            it is printed or electronic format. These Terms of Service has been
            created with the help of the{' '}
            <a href="https://www.termsofservicegenerator.net">
              Terms Of Service Generator
            </a>
            .
          </p>
          <h2>3. Disclaimer</h2>
          <p>
            All the materials on Team Inseparable Esports UG
            (haftungsbeschränkt)’s Website are provided "as is". Team
            Inseparable Esports UG (haftungsbeschränkt) makes no warranties, may
            it be expressed or implied, therefore negates all other warranties.
            Furthermore, Team Inseparable Esports UG (haftungsbeschränkt) does
            not make any representations concerning the accuracy or reliability
            of the use of the materials on its Website or otherwise relating to
            such materials or any sites linked to this Website.
          </p>
          <h2>4. Limitations</h2>
          <p>
            Team Inseparable Esports UG (haftungsbeschränkt) or its suppliers
            will not be hold accountable for any damages that will arise with
            the use or inability to use the materials on Team Inseparable
            Esports UG (haftungsbeschränkt)’s Website, even if Team Inseparable
            Esports UG (haftungsbeschränkt) or an authorize representative of
            this Website has been notified, orally or written, of the
            possibility of such damage. Some jurisdiction does not allow
            limitations on implied warranties or limitations of liability for
            incidental damages, these limitations may not apply to you.
          </p>
          <h2>5. Revisions and Errata</h2>
          <p>
            The materials appearing on Team Inseparable Esports UG
            (haftungsbeschränkt)’s Website may include technical, typographical,
            or photographic errors. Team Inseparable Esports UG
            (haftungsbeschränkt) will not promise that any of the materials in
            this Website are accurate, complete, or current. Team Inseparable
            Esports UG (haftungsbeschränkt) may change the materials contained
            on its Website at any time without notice. Team Inseparable Esports
            UG (haftungsbeschränkt) does not make any commitment to update the
            materials.
          </p>
          <h2>6. Links</h2>
          <p>
            Team Inseparable Esports UG (haftungsbeschränkt) has not reviewed
            all of the sites linked to its Website and is not responsible for
            the contents of any such linked site. The presence of any link does
            not imply endorsement by Team Inseparable Esports UG
            (haftungsbeschränkt) of the site. The use of any linked website is
            at the user’s own risk.
          </p>
          <h2>7. Site Terms of Use Modifications</h2>
          <p>
            Team Inseparable Esports UG (haftungsbeschränkt) may revise these
            Terms of Use for its Website at any time without prior notice. By
            using this Website, you are agreeing to be bound by the current
            version of these Terms and Conditions of Use.
          </p>
          <h2>8. Your Privacy</h2>
          <p>Please read our Privacy Policy.</p>
          <h2>9. Governing Law</h2>
          <p>
            Any claim related to Team Inseparable Esports UG
            (haftungsbeschränkt)'s Website shall be governed by the laws of de
            without regards to its conflict of law provisions.
          </p>
          <hr />
          <p>
            Generated using{' '}
            <a href="https://www.termsofservicegenerator.net">
              Terms of Service Generator
            </a>
          </p>
        </div>
      </>
    }
  />
)

export const DataprivacyDE = ({
  show,
  setShow,
}: {
  show: boolean
  setShow: (_show: boolean) => void
}) => (
  <Flyout
    title="Datenschutzbestimmung"
    show={show}
    onClose={() => setShow(false)}
    content={
      <>
        <div className="container">
          <h2>Disclaimer</h2>
          <p>
            <strong>Haftung für Inhalte</strong>
          </p>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <p>
            <strong>Haftung für Links</strong>
          </p>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
          <p>
            <strong>Urheberrecht</strong>
          </p>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
          <h2>Allgemeine Hinweise</h2>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <h2>Datenerfassung auf dieser Website</h2>
          <p>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            <br />
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten
            <br />
            können Sie dem Impressum dieser Website entnehmen.
            <br />
            Wie erfassen wir Ihre Daten?
            <br />
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um
            <br />
            Daten handeln, die Sie in ein Kontaktformular eingeben.
            <br />
            Andere Daten werden automatisch beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor
            <br />
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die
            <br />
            Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
            betreten.
            <br />
            Wofür nutzen wir Ihre Daten?
            <br />
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere
            <br />
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
            <br />
            Welche Rechte haben Sie bezüglich Ihrer Daten?
            <br />
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer
            <br />
            gespeicherten personenbezogenen Daten zu erhalten. Sie haben
            außerdem ein Recht, die Berichtigung oder
            <br />
            Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen
            zum Thema Datenschutz können Sie
            <br />
            sich jederzeit unter der im Impressum angegebenen Adresse an uns
            wenden. Des Weiteren steht Ihnen ein
            <br />
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            <br />
            Außerdem haben Sie das Recht, unter bestimmten Umständen die
            Einschränkung der Verarbeitung Ihrer
            <br />
            personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie
            der Datenschutzerklärung unter
            <br />
            „Recht auf Einschränkung der Verarbeitung“.
            <br />
            Analyse-Tools und Tools von Drittanbietern
            <br />
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor
            <br />
            allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse
            Ihres Surf-Verhaltens erfolgt in
            <br />
            der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            zurückverfolgt werden.
            <br />
            Sie können dieser Analyse widersprechen oder sie durch die
            Nichtbenutzung bestimmter Tools verhindern.
            <br />
            Detaillierte Informationen zu diesen Tools und über Ihre
            Widerspruchsmöglichkeiten finden Sie in der
            <br />
            folgenden Datenschutzerklärung.
          </p>
          <h2>Hosting und Content Delivery Networks (CDN)</h2>
          <h3>Externes Hosting</h3>
          <p>
            Diese Website wird bei einem externen Dienstleister gehostet
            (Hoster). Die personenbezogenen Daten, die auf dieser Website
            erfasst werden, werden auf den Servern des Hosters gespeichert.
            Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
            und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
            Webseitenzugriffe und sonstige Daten, die über eine Website
            generiert werden, handeln.
          </p>
          <p>
            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
            gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
            lit. b DSGVO) und im Interesse einer sicheren, schnellen und
            effizienten Bereitstellung unseres Online-Angebots durch einen
            professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
          </p>
          <p>
            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
            Erfüllung seiner Leistungspflichten erforderlich ist und unsere
            Weisungen in Bezug auf diese Daten befolgen.
          </p>
          <p>
            <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong>
          </p>
          <p>
            Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
            einen Vertrag über Auftragsverarbeitung mit unserem Hoster
            geschlossen.
          </p>
          <h2>Allgemeine Hinweise und Pflichtinformationen</h2>
          <h3>Datenschutz</h3>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet
            (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitslücken
            aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
            durch Dritte ist nicht möglich.
          </p>
          <h3>Hinweis zur verantwortlichen Stelle</h3>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p>
            Pascal Paula
            <br />
            Feuerdornstraße 6<br />
            86836 Graben
          </p>
          <p>
            Telefon: +491702224047
            <br />
            E-Mail: info@insep.de
          </p>
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <h3>
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </h3>
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
            UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </p>
          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
            ART. 21 ABS. 2 DSGVO).
          </p>
          <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
          <h3>Recht auf Datenübertragbarkeit</h3>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          <h3>SSL- bzw. TLS-Verschlüsselung</h3>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
          <h3>Auskunft, Löschung und Berichtigung</h3>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
            unter der im Impressum angegebenen Adresse an uns wenden.
          </p>
          <h3>Recht auf Einschränkung der Verarbeitung</h3>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen:
          </p>
          <ul>
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <h2>Datenerfassung auf dieser Website</h2>
          <h3>Cookies</h3>
          <p>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert bis Sie diese selbst löschen&nbsp;oder eine automatische
            Lösung durch Ihren Webbrowser erfolgt.
          </p>
          <p>
            Teilweise können auch Cookies von Drittunternehmen auf Ihrem
            Endgerät gespeichert werden, wenn Sie unsere Seite betreten
            (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
            bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur
            Abwicklung von Zahlungsdienstleistungen).
          </p>
          <p>
            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
            technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
            nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
            Anzeige von Videos). Andere Cookies dienen dazu das Nutzerverhalten
            auszuwerten&nbsp;oder Werbung anzuzeigen.
          </p>
          <p>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z. B. Warenkorbfunktion) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Sofern eine entsprechende
            Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
            Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
            auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
            jederzeit widerrufbar.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Soweit Cookies von Drittunternehmen oder zu Analysezwecken
            eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
            Datenschutzerklärung gesondert informieren und ggf. eine
            Einwilligung abfragen.
          </p>
          <h3>Server-Log-Dateien</h3>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </p>
          <ul>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen.
          </p>
          <p>
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der technisch fehlerfreien Darstellung und der Optimierung seiner
            Website – hierzu müssen die Server-Log-Files erfasst werden.
          </p>
          <h3>Kontaktformular</h3>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
            Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </p>
          <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf
            unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir
            ein berechtigtes Interesse an der effektiven Bearbeitung der an uns
            gerichteten Anfragen haben.
          </p>
          <p>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z.&nbsp;B. nach abgeschlossener
            Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </p>
          <h2>Soziale Medien</h2>
          <h3>Facebook Plugins (Like &amp; Share-Button)</h3>
          <p>
            Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
            integriert. Anbieter dieses Dienstes ist die Facebook Ireland
            Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten
            werden nach Aussage von Facebook jedoch auch in die USA und in
            andere Drittländer übertragen.
          </p>
          <p>
            Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
            „Like-Button“ („Gefällt mir“) auf dieser Website. Eine Übersicht
            über die Facebook Plugins finden Sie hier:
            <a
              href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://developers.facebook.com/docs/plugins/?locale=de_DE
            </a>
            .
          </p>
          <p>
            Wenn Sie diese Website besuchen, wird über das Plugin eine direkte
            Verbindung zwischen Ihrem Browser und dem Facebook-Server
            hergestellt. Facebook erhält dadurch die Information, dass Sie mit
            Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Facebook
            „Like-Button“ anklicken während Sie in Ihrem Facebook-Account
            eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem
            Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser
            Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
            wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
            übermittelten Daten sowie deren Nutzung durch Facebook erhalten.
            Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
            von Facebook unter:
            <a
              href="https://de-de.facebook.com/privacy/explanation"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://de-de.facebook.com/privacy/explanation
            </a>
            .
          </p>
          <p>
            Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website
            Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
            Ihrem Facebook-Benutzerkonto aus.
          </p>
          <p>
            Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an einer möglichst umfangreichen Sichtbarkeit in den
            Sozialen Medien.
          </p>
          <h3>Twitter Plugin</h3>
          <p>
            Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden.
            Diese Funktionen werden angeboten durch die Twitter Inc., 1355
            Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das
            Benutzen von Twitter und der Funktion „Re-Tweet“ werden die von
            Ihnen besuchten Websites mit Ihrem Twitter-Account verknüpft und
            anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter
            übertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten
            keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
            Nutzung durch Twitter erhalten. Weitere Informationen hierzu finden
            Sie in der Datenschutzerklärung von Twitter unter:
            <a
              href="https://twitter.com/de/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://twitter.com/de/privacy
            </a>
            .
          </p>
          <p>
            Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an einer möglichst umfangreichen Sichtbarkeit in den
            Sozialen Medien.
          </p>
          <p>
            Ihre Datenschutzeinstellungen bei Twitter können Sie in den
            Konto-Einstellungen unter
            <a
              href="https://twitter.com/account/settings"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://twitter.com/account/settings
            </a>
            ändern.
          </p>
          <h3>Instagram Plugin</h3>
          <p>
            Auf dieser Website sind Funktionen des Dienstes Instagram
            eingebunden. Diese Funktionen werden angeboten durch die Instagram
            Inc., 1601 Willow Road, Menlo Park, CA 94025, USA integriert.
          </p>
          <p>
            Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie
            durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit
            Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
            dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
            dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
            übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
          </p>
          <p>
            Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art.
            6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein berechtigtes
            Interesse an einer möglichst umfangreichen Sichtbarkeit in den
            Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
            wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt
            die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
            a DSGVO; die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
            von Instagram:
            <a
              href="https://instagram.com/about/legal/privacy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://instagram.com/about/legal/privacy/
            </a>
            .
          </p>
          <h3>Tumblr Plugin</h3>
          <p>
            Diese Website nutzt Schaltflächen des Dienstes Tumblr. Anbieter ist
            die Tumblr, Inc., 35 East 21st St, 10th Floor, New York, NY 10010,
            USA.
          </p>
          <p>
            Diese Schaltflächen ermöglichen es Ihnen, einen Beitrag oder eine
            Seite bei Tumblr zu teilen oder dem Anbieter bei Tumblr zu folgen.
            Wenn Sie eine unserer Websites mit Tumblr-Button aufrufen, baut der
            Browser eine direkte Verbindung mit den Servern von Tumblr auf. Wir
            haben keinen Einfluss auf den Umfang der Daten, die Tumblr mit Hilfe
            dieses Plugins erhebt und übermittelt. Nach aktuellem Stand werden
            die IP-Adresse des Nutzers sowie die URL der jeweiligen Website
            übermittelt.
          </p>
          <p>
            Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art.
            6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an einer möglichst umfangreichen Sichtbarkeit in den
            Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
            wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt
            die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
            a DSGVO; die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Weitere Informationen hierzu finden sich in der Datenschutzerklärung
            von Tumblr unter:
            <a
              href="https://www.tumblr.com/privacy/de"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://www.tumblr.com/privacy/de
            </a>
            .
          </p>
          <h2>Plugins und Tools</h2>
          <h3>YouTube mit erweitertem Datenschutz</h3>
          <p>
            Diese Website bindet Videos der YouTube ein. Betreiber der Seiten
            ist die Google Ireland Limited („Google“), Gordon House, Barrow
            Street, Dublin 4, Irland.
          </p>
          <p>
            Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
            bewirkt laut YouTube, dass YouTube keine Informationen über die
            Besucher auf dieser Website speichert, bevor diese sich das Video
            ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
            erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
            So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen
            – eine Verbindung zum Google DoubleClick-Netzwerk her.
          </p>
          <p>
            Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
            Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
            YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
            Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
            YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
            zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
            YouTube-Account ausloggen.
          </p>
          <p>
            Des Weiteren kann YouTube nach Starten eines Videos verschiedene
            Cookies auf Ihrem Endgerät speichern. Mit Hilfe dieser Cookies kann
            YouTube Informationen über Besucher dieser Website erhalten. Diese
            Informationen werden u. a. verwendet, um Videostatistiken zu
            erfassen, die Anwenderfreundlichkeit zu verbessern und
            Betrugsversuchen vorzubeugen. Die Cookies verbleiben auf Ihrem
            Endgerät, bis Sie sie löschen.
          </p>
          <p>
            Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
            Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
            Einfluss haben.
          </p>
          <p>
            Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
            entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung
            zur Speicherung von Cookies), erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Weitere Informationen über Datenschutz bei YouTube finden Sie in
            deren Datenschutzerklärung unter:
            <a
              href="https://policies.google.com/privacy?hl=de"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=de
            </a>
            .
          </p>
          <h3>Google Web Fonts</h3>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von Google bereitgestellt werden. Die Google
            Fonts sind lokal installiert. Eine Verbindung zu Servern von Google
            findet dabei nicht statt.
          </p>
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter
            <a
              href="https://developers.google.com/fonts/faq"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://developers.google.com/fonts/faq
            </a>
            und in der Datenschutzerklärung von Google:
            <a
              href="https://policies.google.com/privacy?hl=de"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=de
            </a>
            .
          </p>
          <h3>Google reCAPTCHA</h3>
          <p>
            Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser
            Website. Anbieter ist die Google Ireland Limited („Google“), Gordon
            House, Barrow Street, Dublin 4, Irland.
          </p>
          <p>
            Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser
            Website (z.&nbsp;B. in einem Kontaktformular) durch einen Menschen
            oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert
            reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
            Merkmale. Diese Analyse beginnt automatisch, sobald der
            Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
            verschiedene Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer
            des Websitebesuchers auf der Website oder vom Nutzer getätigte
            Mausbewegungen). Die bei der Analyse erfassten Daten werden an
            Google weitergeleitet.
          </p>
          <p>
            Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
            Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
            stattfindet.
          </p>
          <p>
            Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art.
            6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse daran, seine Webangebote vor missbräuchlicher
            automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine
            entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung
            zur Speicherung von Cookies), erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
            Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen
            unter folgenden Links:
            <a
              href="https://policies.google.com/privacy?hl=de"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=de
            </a>
            und
            <a
              href="https://policies.google.com/terms?hl=de"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/terms?hl=de
            </a>
            .
          </p>
        </div>
      </>
    }
  />
)
