import React from 'react'

export const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="19"
    height="18"
    viewBox="0 0 19 18"
  >
    <g transform="translate(-64 -14.248)">
      <g
        id="strokecon"
        transform="translate(69 15.248)"
        fill="none"
        stroke="#5e627e"
        strokeWidth="2"
      >
        <circle cx="4" cy="4" r="4" stroke="none" />
        <circle cx="4" cy="4" r="3" fill="none" />
      </g>
      <g transform="translate(0 13.828)">
        <g
          id="strokecon"
          transform="translate(65 12.42)"
          fill="none"
          stroke="#5e627e"
          strokeWidth="2"
        >
          <rect width="17" height="21" rx="4" stroke="none" />
          <rect x="1" y="1" width="15" height="19" rx="3" fill="none" />
        </g>
      </g>
    </g>
  </svg>
)
