import React from 'react'

export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.674"
    height="15.076"
    viewBox="0 0 13.674 15.076"
  >
    <g transform="translate(-82.004 -487.996)" fill="none">
      <path
        d="M93.018,494.638l-7.56-4.43c-.849-.493-1.454-.1-1.454.882v8.889c0,.977.606,1.374,1.454.88l7.6-4.429C93.913,495.938,93.869,495.131,93.018,494.638Z"
        stroke="none"
      />
      <path
        d="M 84.78697967529297 489.996337890625 C 84.30806732177734 489.9962768554688 84.00431060791016 490.3976745605469 84.00431060791016 491.0904846191406 L 84.00431060791016 499.9798278808594 C 84.00430297851562 500.9564208984375 84.60979461669922 501.3543090820312 85.45856475830078 500.8603210449219 L 93.06081390380859 496.4309692382812 C 93.91271209716797 495.9381408691406 93.86930847167969 495.1313171386719 93.01836395263672 494.6384887695312 L 85.45856475830078 490.2081909179688 C 85.21104431152344 490.0644226074219 84.98413848876953 489.996337890625 84.78697967529297 489.996337890625 M 84.78685760498047 487.996337890625 C 85.34796142578125 487.996337890625 85.91191101074219 488.1586303710938 86.46305847167969 488.4787292480469 L 86.46976470947266 488.4826354980469 L 94.02732086181641 492.91162109375 C 95.07711791992188 493.5221862792969 95.67881011962891 494.487060546875 95.67855834960938 495.5599365234375 C 95.67831420898438 496.6177673339844 95.09125518798828 497.5648193359375 94.06764984130859 498.1590576171875 L 94.06766510009766 498.1590881347656 L 86.46540832519531 502.5884399414062 C 85.91286468505859 502.9099731445312 85.34831237792969 503.0727233886719 84.78651428222656 503.0727233886719 C 83.40390777587891 503.0727233886719 82.00431060791016 502.0103149414062 82.00431060791016 499.9798278808594 L 82.00431060791016 491.0904846191406 C 82.00431060791016 489.0591430664062 83.40411376953125 487.996337890625 84.78685760498047 487.996337890625 Z"
        stroke="none"
        fill="#5e627e"
        id="icon"
      />
    </g>
  </svg>
)
