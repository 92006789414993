export const Circle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      id="Ellipse_1"
      data-name="Ellipse 1"
      fill="rgba(0,0,0,0.5)"
      stroke="rgba(255,255,255,0.5)"
      strokeWidth="2"
    >
      <circle cx="8" cy="8" r="8" stroke="none" />
      <circle cx="8" cy="8" r="7" fill="none" />
    </g>
  </svg>
)
