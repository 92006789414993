import { APIContentPlayStats } from 'src/API/Stats'
import { ContentScroll } from 'src/Components/ContentScroll/ContentScroll'
import { MediaItem } from 'src/Components/MediaItem/MediaItem'
import { BrToBr } from 'src/Helper/BrToBr'

export const StatsContent = () => {
  const Stats = APIContentPlayStats()

  return (
    <ContentScroll
      title="team profil"
      items={(Stats || []).map(item => (
        <MediaItem {...item} key={item.title} text={<>{BrToBr(item.text)}</>} />
      ))}
    />
  )
}
