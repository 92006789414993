export const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="Polygon_2" data-name="Polygon 2" fill="rgba(0,0,0,0.5)">
      <path d="M8,0l8,16H0Z" stroke="none" />
      <path
        d="M 7.999999523162842 4.472129821777344 L 3.236064910888672 14 L 12.76393508911133 14 L 7.999999523162842 4.472129821777344 M 7.999999523162842 0 L 16 16 L -9.5367431640625e-07 16 L 7.999999523162842 0 Z"
        stroke="none"
        fill="rgba(255,255,255,0.5)"
      />
    </g>
  </svg>
)
