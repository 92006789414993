import './MediaItem.scss'

import { IMediaItem } from '../../interfaces/IMediaItem'

export const MediaItem = (props: IMediaItem) => (
  <div className="media-item">
    <div className="row">
      <div>
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </div>
      <img src={props.image} alt="" />
    </div>
  </div>
)
