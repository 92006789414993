import './Leaderboard.scss'

import { APILeaderboards } from 'src/API/Leaderboards'

export const Leaderboard = () => {
  const Leaderboards = APILeaderboards()

  return (
    <div className="leader-boards">
      {(Leaderboards || []).map(item => (
        <div key={item.title} className="leader-board">
          <div className="text">
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
          <div>
            <img src={item.image} alt="" />
          </div>
        </div>
      ))}
      <h4 className="title">Leaderboards / Stats</h4>
    </div>
  )
}
