import './Partners.scss'

import { APIPartners } from 'src/API/Partners'
import { VerticalScroll } from 'src/Components/VerticalScroll/VerticalScroll'

export const Partners = () => {
  const Data = APIPartners() || []

  const partners = (
    <>
      {Data.map((item, idx) =>
        item.isSoon ? (
          <div className="item" key={idx}>
            <h4>revealed soon…</h4>
          </div>
        ) : (
          <div className="item" key={item.title}>
            <img src={item.image} alt="" />
            <h3>{item.title}</h3>
            <a href={`//${item.url}`} target="_blank" rel="noopener nofollow">
              {item.url}
            </a>
            <p>{item.text}</p>
          </div>
        )
      )}
    </>
  )

  return (
    <div
      className="page fixed-width fragment-partners"
      id="partners"
      style={{
        backgroundImage: 'url(/images/bg_play.jpg)',
      }}
    >
      <div className="partners">
        <div
          className="info"
          style={{
            backgroundImage: 'url(/images/PartnersBackground.png)',
          }}
        >
          <span>Esport Legends</span>
          <h2>Partners</h2>
          <small>Wanna be our partner?</small>
          <a href="mailto:business@esportlegends.gg">
            business<b>@</b>esportlegends.gg
          </a>
        </div>
        <div className="items">
          {Data.length <= 4 ? (
            partners
          ) : (
            <VerticalScroll>{partners}</VerticalScroll>
          )}
        </div>
      </div>
    </div>
  )
}
