import { APIPlayEarnCollect } from 'src/API/PlayEarnCollect'
import { BrToBr } from 'src/Helper/BrToBr'

import { ContentScroll } from '../../ContentScroll/ContentScroll'
import { MediaItem } from '../../MediaItem/MediaItem'

export const PlayContent = () => {
  const Items = APIPlayEarnCollect()

  return (
    <ContentScroll
      title="play, earn, collect"
      items={(Items || []).map(item => (
        <MediaItem {...item} key={item.title} text={<>{BrToBr(item.text)}</>} />
      ))}
    />
  )
}
