import './Footer.scss'

import { useState } from 'react'
import { APISocialmedia } from 'src/API/Socialmedia'

import * as FA from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataprivacyDE, DataprivacyEN } from './Dataprivacy'
import { ImpressumDE, ImpressumEN } from './Impressum'

export const Footer = () => {
  const Socialmedia = APISocialmedia()
  const [showImpressum, setShowImpressum] = useState(false)
  const [showDataprivacy, setShowDataprivacy] = useState(false)

  const lang = localStorage.getItem('language')

  return (
    <>
      {lang === 'de' ? (
        <ImpressumDE setShow={setShowImpressum} show={showImpressum} />
      ) : (
        <ImpressumEN setShow={setShowImpressum} show={showImpressum} />
      )}
      {lang === 'de' ? (
        <DataprivacyDE setShow={setShowDataprivacy} show={showDataprivacy} />
      ) : (
        <DataprivacyEN setShow={setShowDataprivacy} show={showDataprivacy} />
      )}
      <footer className="page">
        <div></div>
        <div className="socialmedia">
          {Socialmedia?.map(item => {
            // @ts-ignore
            const icon: FA.IconDefinition = FA[item.icon]

            return (
              <a target="_blank" rel="noopener nofollow" href={item.url}>
                <FontAwesomeIcon icon={icon} />
              </a>
            )
          })}
        </div>
        <div className="footer">
          <small>
            <b>&copy; Esport Legends 2022</b> - All rights reserved
          </small>
          <div className="links">
            <a
              onClick={e => {
                e.preventDefault()
                setShowImpressum(true)
              }}
              onKeyDown={e => {
                if (e.key === 'Escape' && showImpressum) {
                  setShowImpressum(false)
                }
              }}
              href="/imprint"
            >
              Impressum
            </a>
            <a
              onClick={e => {
                e.preventDefault()
                setShowDataprivacy(true)
              }}
              onKeyDown={e => {
                if (e.key === 'Escape' && showImpressum) {
                  setShowDataprivacy(false)
                }
              }}
              href="/imprint"
            >
              Privacy Policy
            </a>
            <a
              onClick={e => {
                e.preventDefault()
                setShowDataprivacy(true)
              }}
              onKeyDown={e => {
                if (e.key === 'Escape' && showImpressum) {
                  setShowDataprivacy(false)
                }
              }}
              href="/imprint"
            >
              Terms & Conditions
            </a>
            <a href="mailto:info@esportlegends.gg">Contact Us</a>
          </div>
        </div>
      </footer>
    </>
  )
}
