import './Media.scss'

import { useState } from 'react'
import { APIMedia } from 'src/API/Media'
import { ContentScroll } from 'src/Components/ContentScroll/ContentScroll'

import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MediaItem = ({ uri }: { uri: string }) => {
  const [isVideo, setVideo] = useState(false)

  return (
    <div className="media-item">
      {isVideo ? (
        <iframe
          src={`https://www.youtube.com/embed/${uri}?autoplay=true`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <div onClick={() => setVideo(true)}>
          <img src={`/images/videos/${uri}.jpg`} />
          <FontAwesomeIcon icon={faPlay} />
        </div>
      )}
    </div>
  )
}

export const Media = () => {
  const MediaContent = APIMedia()

  // return <ContentScroll title="" items={} />
  return (
    <div className="page fixed-width fragment-media" id="media">
      <ContentScroll
        start={0}
        title="Media"
        items={(MediaContent || []).map(uri => (
          <MediaItem key={uri} uri={uri} />
        ))}
      />
    </div>
  )
}
