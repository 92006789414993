import './Popup.scss'

import { useEffect, useState } from 'react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IPopupProps {
  title: string
  content: string
  onClose: () => void
}

export const Popup = (props: IPopupProps) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 100)
  }, [])

  const close = () => {
    setShow(false)
    setTimeout(() => {
      props.onClose()
    }, 500)
  }

  return (
    <div
      className={`popup popup-background ${show ? 'active' : ''}`}
      onClick={close}
    >
      <div
        className="popup-container"
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <div className="popup-header">
          {props.title}
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              close()
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
        <div className="popup-content">{props.content}</div>
      </div>
    </div>
  )
}
