import './App.scss'

import { Footer } from './Components/Footer/Footer'
import { Navbar } from './Components/Navbar/Navbar'
import { Media } from './Fragments/Media/Media'
import { PageNews } from './Fragments/News/PageNews'
import { PageHome } from './Fragments/PageHome'
import { Partners } from './Fragments/Partners/Partners'
import { Play } from './Fragments/Play/Play'
import { Roadmap } from './Fragments/Roadmap/Roadmap'
import { Team } from './Fragments/Team/Team'
import { Whatis } from './Fragments/Whatis/Whatis'
import { isDev } from './Helper/isDev'
import { useHorizontalScroll } from './useHorizontalScroll'

const App = () => {
  const [elRef, goLeft, goRight] = useHorizontalScroll()
  const language = localStorage.getItem('language')
  if (!language) {
    localStorage.setItem('language', 'de')
    window.location.reload()
  }
  if (window.location.hash !== '#home') {
    window.history.pushState({}, '', `#home`)
    document.title = `${'home'.toUpperCase()} - Esport Legends`
  }

  return (
    <>
      {isDev && <div id="debug"></div>}
      {/* <div id="currentDev">Letzter Stand: 12.13.14</div> */}
      <div id="main" ref={elRef}>
        <PageHome timestamp={1669550400} />
        <div className="content">
          <Navbar
            goLeft={goLeft}
            goRight={goRight}
            dashed
            items={[
              'home',
              'news',
              'details',
              'media',
              'faq',
              'team',
              'roadmap',
              'partners',
            ]}
            footer={<Footer />}
          />
          <main>
            <PageNews />
            <Play />
            <Media />
            <Whatis />
            <Team />
            <Roadmap />
            <Partners />
            <Footer />
          </main>
        </div>
      </div>
    </>
  )
}

export default App
